export default {
  currency: "{amount}kr",
  calendar: {
    noServiceDialogMessage:
      "Ingen tilbydere tilbyr for øyeblikket denne tjenesten, vennligst velg en annen.",
    selectAnotherService: "Velg en annen tjeneste",
    availableHoursLabel: "Tilgjengelige ",
    hoursOnDateLabel: "Timer på ",
    monday: "Mandag",
    tuesday: "Tirsdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Lørdag",
    sunday: "Søndag",
    january: "Januar",
    february: "Februar",
    march: "Mars",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Desember",
  },
  hourList: {
    noAvailableHours: "Ingen ledige timer for denne dagen",
    findFirstAvailableHour: "Finn første ledige time",
  },
  datePicker: {
    cancel: "Avbryt",
    ok: "OK",
  },
  bookingForm: {
    selectDepartment: "Velg avdeling",
    nameRequired: "Navn er påkrevd",
    addressRequired: "Adresse er påkrevd",
    postalCodeRequired: "Postnummer er påkrevd",
    emailRequired: "E-post er påkrevd",
    emailValid: "E-post må være gyldig",
    phoneRequired: "Telefonnummer er påkrevd",
    phoneValid: "Telefonnummer må være gyldig",
    passwordRequired: "Passord er påkrevd",
    passwordLength: "Passord må være minst 5 tegn",
    service: "Tjeneste",
    description: "Beskrivelse",
    time: "Tid",
    totalDuration: "Totalt tid",
    total: "Totalt",
    selectService: "Vennligst velg en tjeneste.",
    checkboxTooltipBoth:
      "Merk av boksene for å motta bekreftelse/påminnelse for avtalen",
    checkboxTooltipReminder:
      "Merk av boksen for å motta en påminnelse for avtalen",
    checkboxTooltipReceipt:
      "Merk av boksen for å motta bekreftelse for avtalen",
    emailReminder: "Jeg ønsker påminnelse på e-post",
    emailReceipt: "Jeg ønsker bekreftelse på e-post",
    smsReminder: "Jeg ønsker påminnelse på SMS",
    smsReceipt: "Jeg ønsker bekreftelse på SMS",
    newsletterSignup: "Meld meg på nyhetsbrevet",
    bookAppointment: "Bestill time",
    agreeWithConsent: "Jeg godtar avbestillings- og endringsreglene",
    addComment: "Legg til en kommentar",
  },
  bookingStepper: {
    back: "Tilbake",
    selectService: "Velg tjeneste",
    selectTime: "Velg tid",
    yourInformation: "Din informasjon",
    confirm: "Bekreft",
    receipt: "Kvittering",
    thankYou: "Takk for din bestilling!",
    receiptEmail: "Kvittering er sendt til din e-post.",
    reminderEmail:
      "Påminnelse for avtalen vil bli sendt til din e-post dagen før.",
    receiptSMS: "Kvittering er sendt via SMS.",
    reminderSMS:
      "Påminnelse for avtalen vil bli sendt til deg via SMS dagen før.",
  },
  chooseService: {
    selectedServices: "Valgte tjenester",
    continue: "Fortsett",
  },
  dialog: {
    description: "Beskrivelse",
    cancel: "Avbryt",
    confirm: "Bekreft",
    yes: "Ja",
    no: "Nei",
  },
  customerAccount: {
    phoneRequired: "Telefonnummer er påkrevd",
    phoneValid: "Telefonnummer må være gyldig",
    codeRequired: "Kode er påkrevd",
    codeValid: "Kode må være gyldig",
    myPage: "Min Side",
    invalidCode: "Ugyldig kode, prøv igjen",
    confirmCancel: "Er du sikker på at du vil avbestille denne timen?",
    appointmentCancelled: "Din time er avbestilt.",
    cancelLimit: "Du kan bare avbestille {hours} timer i forveien.",
    appointmentChanged: "Du har endret din time!",
    changeTime: "Vil du endre tiden til {date} {time}?",
    phoneNumber: "Ditt telefonnummer",
    smsCode: "Kode fra SMS",
    login: "Logg inn",
    yourAppointments: "Dine Avtaler",
    cancellationPolicy:
      "Avbestilling/Endring av avtale må gjøres minst {hours} timer før den planlagte tiden.",
    service: "Tjeneste",
    time: "Tid",
    move: "Flytt",
    cancel: "Avbestill",
    bookNewAppointment: "Bestill ny time",
    editInformation: "Rediger din informasjon",
    closeEditing: "Lukk redigering",
    editUser: "Rediger bruker",
    save: "Lagre",
    name: "Ditt navn",
    phoneNumberOptional: "Telefonnummer (Valgfritt)",
    email: "E-post",
    emailOptional: "E-post (Valgfritt)",
    address: "Adresse",
    city: "By",
    postalCode: "Postnummer",
    ssn: "Personnummer",
    birthdate: "Fødselsdato",
    gender: "Kjønn",
    male: "Mann",
    female: "Kvinne",
    password: "Passord",
    sendNewPassword: "Send nytt passord",
    enterCodeBelow: "Skriv inn koden nedenfor",
    passwordChanged: "Ditt passord er endret",
    next: "Neste",
    cityRequired: "By er påkrevd",
    countryCode: "Landekode",
  },
  serviceItem: {
    noAvailableTimes: "Ingen tilgjengelige tider for denne tjenesten.",
    today: "i dag",
    tomorrow: "i morgen",
    dayAfterTomorrow: "i overmorgen",
    selectAdditionalServices: "Velg tilleggstjenester",
    continue: "Fortsett",
    confirm: "Bekreft",
    firstAvailable: "Først tilgjengelig",
    hour: "time",
    hours: "timer",
    min: "min",
  },
  mainApp: {
    myPage: "Min Side",
    logout: "Logg ut",
    leaveBooking: "Ønsker du å forlate bestillingen?",
  },
  receiptPage: {
    eventTitle: "Avtale hos {companyName}",
    addToCalendar: "Legg til i kalenderen din",
    bookNewTime: "Bestill ny time",
    goToMyPage: "Gå til min side",
  },
};
